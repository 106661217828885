<style scoped lang="scss">
  .playing_main_box {
    .toolbars {
      .otherbar {
        float: right;
        .selectservicers,.searchbtn  {
          display: inline-block;
        }
        .range_search {
          display: inline-block;
          width: 285px;
          .left {
            width: 185px;
          }
          .center {
            width: 15px;
            display: inline-block;
            text-align: center;
          }
          .right {
            width: 85px;
          }
        }
      }
    }
  }
</style>

<template>
  <div class="playing_main_box">
    <el-alert class="mrgb10" title="请选择或者输入条件后点击查询来执行操作获取统计数据，未输入玩家ID时显示所有玩家的统计数据，否则显示单个玩家的数据!----本页多选服务器不会立即执行，请点击查询按钮!" type="warning"> </el-alert>
    <div class="toolbars mrgb5">
      <div class="otherbar">
        <date-time @commitPicker="getPicker" class="mrgr5 mrgb5" :defaultTime="defaultTime"></date-time>
        <div class="range_search mrgr5 mrgb5">
          <el-input class="left" placeholder="开始值" size="small" clearable v-model="listQuery.keywords">
            <template slot="prepend">竞技场排名</template>
          </el-input>
          <span class="center">-</span>
          <el-input class="right" size="small" clearable v-model="listQuery.keywords" placeholder="结束值"></el-input>
        </div>
        <div class="range_search mrgr5 mrgb5">
          <el-input class="left" placeholder="开始值" size="small" clearable v-model="listQuery.keywords">
            <template slot="prepend">Vip等级</template>
          </el-input>
          <span class="center">-</span>
          <el-input class="right" size="small" clearable v-model="listQuery.keywords" placeholder="结束值"></el-input>
        </div>
        <el-input class="c-el-input mrgr5 mrgb5" size="small" clearable v-model="listQuery.keywords" placeholder="输入玩家ID"></el-input>
        <el-button class="searchbtn mrgr10 mrgb5" type="primary" plain size="small" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
      </div>
    </div>
    
    <div class="clearb mrgb20"></div>
    <template v-for="(opt, index) in options">
      <echart id="Echart" class="mrgb20" :key="index" width="100%" height="60vh" :option="options[index]"></echart>
    </template>
    <!--主线精英个人数据-->
    <elite :ref="refPlaying" :gamerId="gamerId"></elite>
  </div>
</template>

<script>
  import dateTime from "@/components/DateTime"
  import echart from "@/components/Echarts/Index"
  import elite from "./components/elite"
  import moment from 'moment'
  export default {
    name: "playingMain",
    components: {
      dateTime,
      echart,
      elite
    },
    data() {
      return {
        listQuery: {
          serverIds: [], // 选中的服务器
          keywords: "",
          beginTime: 0,
          endTime: 0
        },
        serverNames: [], // 选中的服务器名称
        servicersDefault: this.$store.getters.servicer ? [this.$store.getters.servicer.id] : [], // 多选服务器默认选中
        defaultTime: (() => { // 默认选中时间
          let nowTime = moment().format('YYYY-MM-DD 23:59:59')
          let oldTime = moment().subtract(7, 'days').format('YYYY-MM-DD 00:00:00')
          return [oldTime, nowTime]
        })(),
        options: [{}],
        gamerId: 0,
        refPlaying: "refPlayingMainToElite"
      }
    },
    mounted() {
      this.$nextTick(() => {
        this._search()
      })
    },
    methods: {
      _search() {
        if (this.listQuery.keywords) {
          this.gamerId = parseInt(this.listQuery.keywords)
          this.$refs[this.refPlaying].showDialog = true
          return false
        }
        this.options = []
        this.getEchartData()
      },
      getEchartData() {
        let legendData = []
        let xAxisData = []
        let series = []
        legendData = ["手动挑战次数", "扫荡次数"]
        for (let i = 1; i <= 31; i++) {
          let time = "2019-08-" + (i < 10 ? "0" + i : i)
          xAxisData.push(time)
        }
        for (let i = 0; i < legendData.length; i++) {
          let temp = {
            name: legendData[i],
            type: "line",
            stack: "basic",
            smooth: false,
            //areaStyle: {},
            label: {
              show: true,
              position: "top",
              distance: 10
            },
            /*barWidth: "30%",
            barMaxWidth: "20px",
            barMinHeight: "10",*/
            data: []
          }
          for (let k = 0; k < 31; k++) {
            let item = Math.floor(Math.random() * 100)
            temp.data.push(item)
          }
          series.push(temp)
        }
        this.options.push(this.setOptions(legendData, xAxisData, series))
      },
      setOptions(legendData, xAxisData, series) {
        let that = this
        let startPer = 0
        let endPer = 100
        let len = xAxisData.length
        if (len > 20) {
          startPer = Math.floor((len - 20) * 100 / len)
        }
        let curOption = {
          title: {
            text: ''
          },
          tooltip: {
            trigger: "axis"
          },
          legend: {
            data: legendData
          },
          grid: {
            containLabel:true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          dataZoom: {
            show: true,
            start: startPer,
            end: endPer
          },
          xAxis: {
            type: "category",
            //boundaryGap: true,
            axisLabel: {
              rotate: 30,
              interval: 0
            },
            data: xAxisData
          },
          yAxis: {
            type: "value",
            axisTick: {
              
            },
            axisLabel: {
              formatter: "{value}"
            }
          },
          series: series
        }
        return curOption
      },
      getPicker(val) {
        this.listQuery.beginTime = val[0] ? this.toUnixStamp(val[0], true) : 0
        this.listQuery.endTime = val[1] ? this.toUnixStamp(val[1], true) : 0
      }
    }
  }
</script>