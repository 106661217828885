<!--组件----主线和精英个人数据-->
<style scoped lang="scss">
  /* 单独定义弹窗样式 */
  /deep/ .c-el-dialog.elite-el-dialog {
    margin-top: 5vh !important;
    max-width: 750px;
    .elite_content {
      padding: 0 30px;
    }
  }
</style>

<template>
  <el-dialog title="单个玩家副本数据" custom-class="c-el-dialog elite-el-dialog" 
    :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
    <el-collapse v-model="activeNames">
      <template v-for="(item, index) in datas">
        <el-collapse-item :key="index" :title="item.title" :name="index">
          <div class="elite_content">
            <el-divider content-position="left">手动挑战数据</el-divider>
            <el-table :data="item && item.elites"
              style="width: 100%" border size="mini">
              <el-table-column label="时间" prop="time"></el-table-column>
              <el-table-column label="关卡" prop="customs"></el-table-column>
              <el-table-column label="等级" prop="level"></el-table-column>
            </el-table>
            <el-divider content-position="left">扫荡数据</el-divider>
            <el-table :data="item && item.mops"
              style="width: 100%" border size="mini">
              <el-table-column label="时间" prop="time"></el-table-column>
              <el-table-column label="关卡" prop="customs"></el-table-column>
              <el-table-column label="等级" prop="level"></el-table-column>
            </el-table>
          </div>
        </el-collapse-item>
      </template>
    </el-collapse>
  </el-dialog>
</template>

<script>
  export default {
    name: "elite",
    props: {
      gamerId: {
        default: 0,
        type: Number
      }
    },
    data() {
      return {
        curGamerId: this.gamerId,
        showDialog: false,
        datas: [{
          elites: [], // 挑战数据
          mops: []  // 扫荡数据
        }],
        activeNames: []
      }
    },
    watch: {
      showDialog(v) {
        this.activeNames = []
        if (v) {
          this.curGamerId = v
          this.getGamerData()
        }
      }
    },
    methods: {
      getGamerData() {
        this.datas = [
          {
            title: "2019-11-22",
            elites: [
              { time: "2019-11-22 17:19:49", customs: 10, level: 25 },
              { time: "2019-11-22 14:15:15", customs: 8, level: 11 },
              { time: "2019-11-22 13:20:20", customs: 3, level: 8 },
              { time: "2019-11-22 11:00:00", customs: 2, level: 5 }
            ],
            mops: [
              { time: "2019-11-22 09:19:49", customs: 7, level: 12 },
              { time: "2019-11-22 07:15:15", customs: 6, level: 9 },
              { time: "2019-11-22 05:20:20", customs: 4, level: 6 },
              { time: "2019-11-22 03:00:00", customs: 1, level: 3 },
            ]
          },
          {
            title: "2019-11-21",
            elites: [
              { time: "2019-11-21 17:19:49", customs: 10, level: 25 },
              { time: "2019-11-21 14:15:15", customs: 8, level: 11 },
              { time: "2019-11-21 13:20:20", customs: 3, level: 8 },
              { time: "2019-11-21 11:00:00", customs: 2, level: 5 }
            ],
            mops: [
              { time: "2019-11-21 09:19:49", customs: 7, level: 12 },
              { time: "2019-11-21 07:15:15", customs: 6, level: 9 },
              { time: "2019-11-21 05:20:20", customs: 4, level: 6 },
              { time: "2019-11-21 03:00:00", customs: 1, level: 3 },
            ]
          }
        ]
        console.log(this.datas)
      },
      _close() {
        this.showDialog = false
      }
    }
  }
</script>